import { UserData } from '@tm/types/common/app-api/auth'
import mixpanel from 'mixpanel-browser'
import { trackClient } from '../../track'

// Get Mixpanel project token from "env variables", put in there by Webpack.
const token = process.env.MIXPANEL_TOKEN || ''
mixpanel.init(token, { api_host: process.env.MIXPANEL_PROXY_URL })

/** Initialize Mixpanel and the TrackClient event mirroring */
export const updateMixpanel = (userData: UserData) => {
  const {
    contact_id,
    email: contact_email,
    name: contact_name,
    organization,
    ip,
    marketingSource,
    phone,
    employeeCount: employee_count,
    onboardingUsage,
    lang,
  } = userData

  if (!organization) return

  const { appSubscriptionInfo = { plan_id: undefined } } = organization
  const { plan_id: subscription_plan_id } = appSubscriptionInfo

  const marketingProps: { [key: string]: string } = {}

  // Include marketingSource to mixpanel profile.
  // Object has landingpage, utm tags & referer info from where user came to our website before creating account.
  if (marketingSource && Object.keys(marketingSource).length > 0) {
    for (const key in marketingSource) {
      marketingProps[`marketing_${key}`] = marketingSource[key]
    }
  }

  // Marketing needs utm_source to be Referral/Affiliate if ucc or gr_pk is set (Cello and Get Reditus apps)
  if (marketingSource?.ucc) marketingProps['marketing_utm_source'] = 'Referral'
  if (marketingSource?.gr_pk) marketingProps['marketing_utm_source'] = 'Affiliate'

  const contactProps = {
    distinct_id: contact_id,
    organization_id: organization.id,
    organization_name: organization.name,
    operating_company_id: organization.operating_company_id,
    $email: contact_email,
    $name: contact_name,
    subscription_plan_id,
    ip,
    phone,
    employee_count,
    onboarding_usage: onboardingUsage,
    browser_lang: navigator?.language?.split('-')[0],
    app_lang: lang,
    ...marketingProps,
  }

  mixpanel.identify(contact_id)

  const definedContactProps = Object.keys(contactProps).reduce<Record<string, string>>(
    (data, key: keyof typeof contactProps) => {
      const value = contactProps[key]
      if (typeof value === 'string') {
        data[key] = value
      }
      return data
    },
    {}
  )

  if (Object.keys(definedContactProps).length) mixpanel.people.set(definedContactProps)

  const orgProps = {
    distinct_id: organization.id,
    $name: organization.name,
    operating_company_id: organization.operating_company_id,
    subscription_plan_id,
    ...marketingProps,
  }

  const definedOrgProps = Object.keys(orgProps).reduce<Record<string, string>>((data, key: keyof typeof orgProps) => {
    const value = orgProps[key]
    if (typeof value === 'string') {
      data[key] = value
    }
    return data
  }, {})

  if (Object.keys(definedOrgProps).length) mixpanel.get_group('organization_id', organization.id).set(definedOrgProps)

  mixpanel.track('PAGE_LOAD')

  // Initialize TrackClient
  trackClient.setContext({ organizationId: organization.id, contactId: contact_id })
  trackClient.send({ event: 'PAGE_LOAD' })
}

export const resetMixpanel = () => {
  mixpanel.reset()
}
